import React from 'react'
import PropTypes from 'prop-types'

import { Rating, Typography } from '@mui/material'
import { ReviewRatingContainer, SuccessRatingContainer } from './styled'

const ReviewRating = ({ isMobile, isSuccessPage, dtId, handleRating, order, labelColor, rating }) =>
  isSuccessPage ? (
    <SuccessRatingContainer isMobile={isMobile}>
      <Typography color={labelColor} fontSize="16px" htmlFor="rating" textTransform="uppercase">
        Rate Your Product
      </Typography>
      <Rating
        sx={{ fontSize: '4rem' }}
        data-testid={dtId}
        id="rating"
        value={rating}
        onChange={(e, newValue) => {
          handleRating(order, newValue)
        }}
      />
    </SuccessRatingContainer>
  ) : (
    <ReviewRatingContainer>
      <Typography color={labelColor} component="label" htmlFor="rating">
        Overall Rating:
      </Typography>
      <Rating data-testid={dtId} id="rating" value={rating} onChange={(e, newValue) => handleRating(newValue)} />
    </ReviewRatingContainer>
  )

ReviewRating.propTypes = {
  isMobile: PropTypes.bool,
  isSuccessPage: PropTypes.bool,
  dtId: PropTypes.string,
  handleRating: PropTypes.func,
  order: PropTypes.object,
  labelColor: PropTypes.string,
  rating: PropTypes.number,
}

export default ReviewRating
