import React from 'react'
import PropTypes from 'prop-types'
import { CardMedia, Skeleton } from '@mui/material'
import { ReviewImageContainer } from './styled'

const ProductCardImage = ({ dtId, isSuccess, primaryImage, title }) => (
  <ReviewImageContainer isSuccess={isSuccess}>
    {!primaryImage ? (
      <Skeleton width="100%" sx={{ mr: 2, aspectRatio: '5/4' }} />
    ) : (
      <CardMedia
        component="img"
        aria-hidden="true"
        role="presentation"
        alt={title}
        title={title}
        image={primaryImage ?? null}
        data-testid={dtId}
      />
    )}
  </ReviewImageContainer>
)

ProductCardImage.propTypes = {
  dtId: PropTypes.string,
  isSuccess: PropTypes.bool.isRequired,
  primaryImage: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default ProductCardImage
