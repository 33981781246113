import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { decodeTrademarkEntity } from '@helpers/string-helper'
import { fetchProductBySku } from '@services/product'
import useReviewSubmission from '@hooks/useReviewSubmission'
import { Dialog, Stack, useMediaQuery, styled, Grid, Typography, CircularProgress, Box } from '@mui/material'
import ReviewProductInfo from '@components/review-product/ReviewProductInfo'
import ReviewForm from '@components/review-product/ReviewForm'
import ReviewProductErrorModal from '@components/review-product/ReviewProductErrorModal'
import { StyledCloseIcon, StyledBox } from './styled'

const ReviewProductModal = ({
  searchParams,
  initialRating,
  open,
  handleClose,
  order,
  setSku,
  setImageURL,
  setTitle,
  setUnreviewedItems,
}) => {
  const [reviewStatus, setReviewStatus] = useState('')
  const [product, setProduct] = useState(null)
  const [pastOrders, setPastOrders] = useState({})
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
  const [errorText, setErrorText] = useState('')
  const isMobile = useMediaQuery('(max-width:1024px)')
  const dataTestIdPrefix = isMobile ? 'mobile-review-' : 'desktop-review-'

  const prodSKU = order?.sku ?? ''
  const prodImageURL = order?.imageUrl ?? ''
  const prodTitle = order?.title ? decodeTrademarkEntity(order.title) : ''

  const [requestUrl, setUrl] = useState('')
  const [options, setOptions] = useState(null)

  const { data: reviewData, isLoading } = useReviewSubmission(requestUrl, options)

  useEffect(() => {
    if (reviewData?.status === 200) {
      setPastOrders(reviewData?.unreviewedItems?.purchasedProducts)
      setReviewStatus('success')
    } else if (reviewData?.status) {
      try {
        console.error(reviewData?.error?.message || reviewData?.message || reviewData[0]?.message || 'unknown error')
        setErrorText(reviewData?.error?.message)
        setReviewStatus('error')
      } catch {
        console.error('HTTP Error: ', reviewData?.status)
        setReviewStatus('error')
      }
    }
  }, [reviewData, requestUrl, options])

  useEffect(() => {
    const getProduct = async sku => {
      const productData = await fetchProductBySku(sku)
      setProduct({ sku, title: productData.title, imageURL: productData.primary_image })
    }

    if (prodSKU) {
      getProduct(prodSKU)
    }
  }, [prodSKU])

  useEffect(() => {
    if (reviewStatus === 'error') {
      setIsErrorModalOpen(true)
    } else {
      setIsErrorModalOpen(false)
    }
  }, [reviewStatus, setIsErrorModalOpen])

  const handleCloseErrorModal = () => setReviewStatus('')

  // see comments at EOF for handleSubmit API response examples
  const handleSubmit = review => {
    const turntoNotificationData = searchParams?.turntoNotificationData
    if (!turntoNotificationData) {
      setReviewStatus('error') // prevent submitting review without token
    } else {
      const url = `${process.env.GATSBY_PRODUCT_REVIEWS}/v1`
      const body = { ...review, catalogItems: [{ sku: prodSKU }], turntoNotificationData }
      const opts = {
        body: JSON.stringify(body),
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        method: 'POST',
      }
      setUrl(url)
      setOptions(opts)
    }
  }

  // Change state instead of producing new success page
  if (reviewStatus === 'success') {
    handleClose()
    setSku(prodSKU)
    setImageURL(prodImageURL)
    setTitle(prodTitle)
    setReviewStatus('')
    setUnreviewedItems(pastOrders)
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={1024}>
      {isLoading ? (
        <StyledBox sx={{ width: 300, height: 300 }}>
          <CircularProgress />
        </StyledBox>
      ) : (
        <>
          <Stack direction={{ sm: 'column', md: 'row' }}>
            <ReviewProductInfo
              showYourOrder
              dataTestIdPrefix={dataTestIdPrefix}
              prodImageURL={prodImageURL}
              prodSKU={prodSKU}
              prodTitle={prodTitle}
            />
            <StyledCloseIcon sx={{ color: '#808080' }} onClick={handleClose} />
            <ReviewForm
              initialRating={initialRating}
              inidataTestIdPrefix={dataTestIdPrefix}
              handleSubmit={handleSubmit}
            />
          </Stack>
          <ReviewProductErrorModal
            errorText={errorText}
            dataTestIdPrefix={dataTestIdPrefix}
            isModalOpen={isErrorModalOpen}
            handleClose={handleCloseErrorModal}
          />
        </>
      )}
    </Dialog>
  )
}

ReviewProductModal.propTypes = {
  searchParams: PropTypes.object,
  initialRating: PropTypes.number,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  order: PropTypes.object,
  setSku: PropTypes.func,
  setImageURL: PropTypes.func,
  setTitle: PropTypes.func,
  setUnreviewedItems: PropTypes.func,
}

export default ReviewProductModal
