import {
  styled,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'

/* ProductCardImage Styled Components */
export const ReviewImageContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'isSuccess',
})(({ isSuccess, theme }) => ({
  width: isSuccess ? 100 : 'auto',
  '& img': {
    aspectRatio: '3/2',
    objectFit: 'contain !important',
    backgroundColor: theme.palette.secondary.light,
    padding: isSuccess ? '0 8px' : '0 48px',
    color: 'transparent',
    'img:-moz-loading': {
      visibility: 'hidden',
    },
    [theme.breakpoints.down('md')]: {
      padding: isSuccess ? '0 8px' : '0 26px',
    },
  },
}))

/* ReviewForm Styled Components */
export const PrivacyPolicyTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '19px',
  '& a': {
    color: theme.palette.primary.main,
    fontSize: '14px',
    textDecoration: 'underline',
  },
}))

export const ReviewFieldTip = styled(Stack, {
  shouldForwardProp: prop => prop !== 'hasError',
})(({ theme, hasError }) => ({
  marginTop: 8,
  '& > span': {
    color: hasError ? theme.palette.error.main : theme.palette.grey[600],
    fontSize: 12,
  },
  '& svg': {
    fill: hasError ? theme.palette.error.main : theme.palette.grey[600],
    fontSize: '18px',
    marginRight: '4px',
  },
}))

export const ReviewFieldWrapper = styled(Box, {
  shouldForwardProp: prop => prop !== 'hasError',
})(({ theme, hasError }) => ({
  '& > label': {
    color: hasError ? theme.palette.error.main : theme.palette.primary.dark, // #003566
    margin: 0,
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: '16px',
    '& > span': {
      color: theme.palette.grey[600],
    },
  },
  '& div > input': {
    backgroundColor: theme.palette.common.white,
    fontSize: 18,
    padding: '6px 14px !important',
  },
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.common.white,
    '& > textarea': { fontSize: 18, height: 'auto !important' },
  },
}))

export const ReviewFormFooter = styled(Grid)(() => ({
  marginTop: '42px !important',
  '& > button': { width: 250, marginRight: 10 },
}))

export const ReviewFormWrapper = styled(Card)(() => ({
  margin: 'auto',
  '& > form': {
    backgroundColor: 'rgba(0, 83, 160, 0.04)',
    padding: 24,
    height: '100%',
    maxWidth: '600px',
  },
}))

export const ReviewHeaderTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: '23px',
  fontWeight: 400,
  lineHeight: '31px',
}))

export const ValidationErrorTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: '12px',
  marginTop: '0 !important',
}))

/* ReviewGuidelines Styled Components */
export const ReviewAccordion = styled(Accordion)(() => ({
  boxShadow: 'none',
}))

export const ReviewAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: 16,
  '& p, ul': { marginBottom: 16 },
  '& ul': { paddingLeft: '20px', listStyleType: 'disc' },
  '& li': { marginBottom: 6 },
  '& ul > p': { margin: '0 0 6px -20px' },
  '& p, li': {
    color: theme.palette.primary.dark,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '19px',
  },
  '& p:last-child': { marginBottom: 0 },
  '& a': {
    color: theme.palette.primary.main,
    fontSize: '14px',
    textDecoration: 'underline',
  },
}))

export const ReviewAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: 'rgba(0, 83, 160, 0.04)',
  justifyContent: 'flex-start',
  flexGrow: 'unset',
  padding: 0,
  margin: 0,
  minHeight: 'unset',
  '& div': { margin: 0, flexGrow: 'unset' },
  '& .Mui-expanded': { minHeight: 'unset' },
  '& svg': { fill: theme.palette.primary.main, margin: '0 8px' },
}))

export const ReviewGuidelinesTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '19px',
  textDecoration: 'underline',
}))

/* ReviewProductErrorModal Styled Components */
export const ErrorFooterWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  margin: '40px 0 20px',
}))

export const ErrorHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: '20px',
  marginBottom: '16px',
  [theme.breakpoints.down('sm')]: {
    paddingTop: '8px',
  },
}))

export const ErrorHeaderTypography = styled(Typography)(({ theme }) => ({
  fontSize: '23px',
  color: theme.palette.error.main,
  lineHeight: '30px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '19px',
  },
}))

export const ErrorIcon = styled(WarningAmberIcon)(({ theme }) => ({
  color: theme.palette.error.main,
  marginRight: '5px',
  height: '22px',
  width: '22px',
}))

export const ErrorMessageTypography = styled(Typography)(() => ({
  lineHeight: '22px',
  textAlign: 'center',
}))

export const StyledButton = styled(Button)(() => ({
  fontWeight: 600,
  lineHeight: '20px',
  maxWidth: '250px',
  height: '36px',
}))

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    '& .MuiDialog-paper': {
      margin: '32px 16px',
    },
  },
}))

/* ReviewProductInfo Styled Components */
export const ProductCardContent = styled(CardContent, {
  shouldForwardProp: prop => prop !== 'isSuccess',
})(({ isSuccess, theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  paddingLeft: 16,
  paddingRight: 0,
  width: '100%',
  [theme.breakpoints.down('md')]: {
    paddingLeft: !isSuccess ? 0 : 16,
  },
}))

export const ProductSKUTypography = styled(Typography, {
  shouldForwardProp: prop => prop !== 'isSuccess',
})(({ isSuccess, theme }) => ({
  color: theme.palette.secondary.dark,
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '19px',
  [theme.breakpoints.down('md')]: {
    fontSize: isSuccess ? 12 : 14,
  },
}))

export const ProductTitleTypography = styled(Typography, {
  shouldForwardProp: prop => prop !== 'isSuccess',
})(({ isSuccess, theme }) => ({
  color: theme.palette.secondary.dark,
  fontSize: 19,
  fontWeight: 400,
  lineHeight: '26px',
  [theme.breakpoints.down('md')]: {
    fontSize: isSuccess ? 16 : 19,
  },
}))

export const ProductWrapper = styled(Box, {
  shouldForwardProp: prop => prop !== 'isSuccess',
})(({ isSuccess, theme }) => ({
  padding: isSuccess ? '6px 16px' : 16,
  width: 'min(45vw, 600px)',
  display: isSuccess ? 'flex' : 'block',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    padding: isSuccess ? '6px 8px' : 16,
    maxWidth: 600,
    width: 'unset',
  },
}))

export const YourOrderTypography = styled(Typography, {
  shouldForwardProp: prop => prop !== 'isSuccess',
})(({ isSuccess, theme }) => ({
  color: 'rgba(51, 51, 51, 0.6)',
  fontSize: 10,
  fontWeight: 300,
  lineHeight: '14px',
  marginTop: isSuccess ? 0 : 16,
  textTransform: 'uppercase',
  [theme.breakpoints.down('md')]: {
    marginTop: 0,
  },
}))

/* ReviewProductSuccess Styled Components */
export const SliderWrapper = styled('div')(({ theme }) => ({
  marginTop: '26px',
  padding: '0 8px',
  [theme.breakpoints.down('sm')]: {
    marginTop: '10px',
  },
}))

export const SuccessIcon = styled(CheckCircleOutlinedIcon)(() => ({
  marginRight: '5px',
  marginBottom: '1px',
  height: '18px',
  width: '18px',
}))

export const SuccessTypography = styled(Typography)(({ theme }) => ({
  fontSize: '19px',
  color: theme.palette.success.main,
  lineHeight: '25px',
}))

export const SuccessWrapper = styled('div')(({ theme }) => ({
  color: theme.palette.success.main,
  display: 'flex',
  alignItems: 'center',
  paddingTop: '16px',
  marginBottom: '16px',
  [theme.breakpoints.down('sm')]: {
    paddingTop: '8px',
  },
}))

export const Wrapper = styled('div')(() => ({
  marginBottom: '44px',
  padding: '15px',
  backgroundColor: 'white',
  minHeight: '700px',
}))

export const YouMayAlsoLikePaper = styled('div')(() => ({
  marginTop: '16px',
  paddingTop: '16px',
}))

export const YouMayAlsoLikeTypography = styled(Typography)(({ theme }) => ({
  lineHeight: '26px',
  [theme.breakpoints.down('sm')]: {
    marginTop: '28px',
  },
}))

/* ReviewRating Styled Components */
export const ReviewRatingContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 24,
  marginTop: '32px !important',
  '& label': { fontSize: '1.5rem' },
  '> label': { fontSize: '18px' },
}))

export const SuccessRatingContainer = styled(Box)(({ isMobile }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginLeft: isMobile ? '33%' : '',
  paddingLeft: isMobile ? '16px' : '',
  '& label': { fontSize: '1.5rem' },
}))

/* Review Modal Componenets */
export const StyledCloseIcon = styled(CloseOutlinedIcon)({
  position: 'absolute',
  right: 0,
  margin: '10px',
  cursor: 'pointer',
})

export const StyledBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

/* Past Orders Components */
export const PastOrderContainer = styled(Box)({
  display: 'flex',
})
