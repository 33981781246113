import React from 'react'
import PropTypes from 'prop-types'

import { Skeleton } from '@mui/material'
import ProductCardImage from './ProductCardImage'
import {
  ProductCardContent,
  ProductSKUTypography,
  ProductTitleTypography,
  ProductWrapper,
  YourOrderTypography,
} from './styled'

const ReviewProductInfo = ({
  dataTestIdPrefix,
  isSuccess = false,
  showYourOrder,
  prodImageURL,
  prodSKU,
  prodTitle,
}) => (
  <ProductWrapper isSuccess={isSuccess}>
    <ProductCardImage
      dtId={`${dataTestIdPrefix}product-image`}
      isSuccess={isSuccess}
      primaryImage={prodImageURL}
      title={prodTitle}
    />

    <ProductCardContent isSuccess={isSuccess}>
      {showYourOrder && <YourOrderTypography isSuccess={isSuccess}>Your Order</YourOrderTypography>}
      {prodTitle ? (
        <ProductTitleTypography data-testid={`${dataTestIdPrefix}product-name`} isSuccess={isSuccess}>
          {prodTitle}
        </ProductTitleTypography>
      ) : (
        <Skeleton sx={{ fontSize: '30px', width: '66%' }} />
      )}
      {prodSKU ? (
        <ProductSKUTypography data-testid={`${dataTestIdPrefix}product-sku`} isSuccess={isSuccess}>
          {`SKU: ${prodSKU}`}
        </ProductSKUTypography>
      ) : (
        <Skeleton sx={{ fontSize: '20px', width: '33%' }} />
      )}
    </ProductCardContent>
  </ProductWrapper>
)

ReviewProductInfo.propTypes = {
  dataTestIdPrefix: PropTypes.string,
  isSuccess: PropTypes.bool,
  showYourOrder: PropTypes.bool,
  prodImageURL: PropTypes.string,
  prodSKU: PropTypes.string,
  prodTitle: PropTypes.string,
}

export default ReviewProductInfo
