import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Button, CircularProgress, Divider, Stack, TextField, useTheme } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import ReviewGuidelines from './ReviewGuidelines'
import ReviewRating from './ReviewRating'
import {
  PrivacyPolicyTypography,
  ReviewFieldTip,
  ReviewFieldWrapper,
  ReviewFormFooter,
  ReviewFormWrapper,
  ReviewHeaderTypography,
  ValidationErrorTypography,
} from './styled'

const ReviewForm = ({ isLoading, initialRating, dataTestIdPrefix, handleSubmit }) => {
  const [rating, setRating] = useState(initialRating ?? 0)
  const [reviewText, setReviewText] = useState('')
  const [reviewTitle, setReviewTitle] = useState('')
  const [errors, setErrors] = useState({ rating: false, text: false, title: false })
  const { palette } = useTheme()
  const hasRatingsError = errors.rating
  const hasTextError = errors.text
  const hasTitleError = errors.title

  const resetError = field => setErrors(current => ({ ...current, [field]: false }))

  const handleRatingChange = newRating => {
    if (hasRatingsError) resetError('rating')
    setRating(newRating)
  }

  const handleTextChange = e => {
    if (hasTextError) resetError('text')
    setReviewText(e?.target?.value.trim() ?? '')
  }

  const handleTitleChange = e => {
    const newTitle = e?.target?.value.trim() ?? ''
    if (hasTitleError) resetError('title')
    if (hasTextError && !newTitle) resetError('text') // if title is deleted, then clear text error
    setReviewTitle(newTitle)
  }

  const handleButtonClick = () => {
    const newErrors = {
      rating: ![1, 2, 3, 4, 5].includes(rating),
      // Show text error if the review is too short or if there is a title with no review text
      text: !!((reviewText && reviewText.length < 10) || (reviewTitle && !reviewText)),
      title: !!(reviewTitle && reviewTitle.length > 150),
    }

    if (newErrors.rating || newErrors.text || newErrors.title) {
      setErrors(newErrors)
    } else {
      const review = { rating }
      if (reviewText) review.text = reviewText
      if (reviewTitle) review.title = reviewTitle
      handleSubmit(review)
    }
  }

  return (
    <ReviewFormWrapper>
      <Stack component="form" spacing={2} noValidate autoComplete="off">
        <ReviewHeaderTypography>Rate Your Product</ReviewHeaderTypography>
        <ReviewGuidelines dataTestIdPrefix={dataTestIdPrefix} />

        <ReviewRating
          dtId={`${dataTestIdPrefix}rating-input`}
          handleRating={handleRatingChange}
          labelColor={hasRatingsError ? palette.error.main : palette.primary.dark}
          rating={rating}
        />
        {hasRatingsError && <ValidationErrorTypography>Please select number of rating stars</ValidationErrorTypography>}
        <Divider sx={{ mt: hasRatingsError ? '0 !important' : 2 }} />

        <ReviewFieldWrapper hasError={hasTitleError}>
          <label htmlFor="review-title">
            Give your review a title: <span>(Optional)</span>
          </label>
          <TextField
            fullWidth
            id="review-title"
            inputProps={{ 'data-testid': `${dataTestIdPrefix}title-input`, maxLength: 150 }}
            onChange={handleTitleChange}
            size="small"
          />
          <ReviewFieldTip direction="row" hasError={hasTitleError}>
            <InfoOutlinedIcon />
            <span>
              {hasTitleError
                ? 'Your review title must be 150 characters or less'
                : 'Your overall impression (150 characters or less)'}
            </span>
          </ReviewFieldTip>
        </ReviewFieldWrapper>

        <ReviewFieldWrapper hasError={hasTextError}>
          <label htmlFor="review-description">
            Tell us about your product: <span>{reviewTitle ? '(Required)' : '(Optional)'}</span>
          </label>
          <TextField
            inputProps={{ 'data-testid': `${dataTestIdPrefix}description-input`, maxLength: 5000 }}
            fullWidth
            id="review-description"
            multiline
            onChange={handleTextChange}
            rows={4}
            size="small"
          />
          <ReviewFieldTip direction="row" hasError={hasTextError}>
            <InfoOutlinedIcon />
            <span>
              {hasTextError
                ? 'Your review must be at least 10 characters in length'
                : "Make your review great: Describe what you liked, what you didn't like, and other key things shoppers should know (minimum 10 characters)"}
            </span>
          </ReviewFieldTip>
        </ReviewFieldWrapper>

        <PrivacyPolicyTypography marginTop="32px !important">
          Do not include personal information in your review (email, phone number, full name, etc.). Your review,
          including any personal information, may be published on our website. If you would like to contact customer
          service, please click{' '}
          <a href="mailto:tellus@roomstogo.com" data-testid={`${dataTestIdPrefix}tellus-email-link`}>
            here<span className="hide508">(opens in new e-mail window)</span>
          </a>
          .
        </PrivacyPolicyTypography>

        <PrivacyPolicyTypography marginTop="16px !important">
          By submitting a review, I agree to the{' '}
          <a
            data-testid={`${dataTestIdPrefix}privacy-policy-link`}
            href="https://legal.roomstogo.com/rtg-online#contract-fxauo3tp2"
            target="_blank"
          >
            Privacy Policy<span className="hide508">(opens in new window)</span>
          </a>{' '}
          of Rooms To Go.
        </PrivacyPolicyTypography>

        <ReviewFormFooter container justifyContent={{ xs: 'center', md: isLoading ? 'center' : 'flex-end' }}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button data-testid={`${dataTestIdPrefix}submit-button`} onClick={handleButtonClick} variant="contained">
              Submit Review
            </Button>
          )}
        </ReviewFormFooter>
      </Stack>
    </ReviewFormWrapper>
  )
}

ReviewForm.propTypes = {
  isLoading: PropTypes.bool,
  initialRating: PropTypes.number,
  dataTestIdPrefix: PropTypes.string,
  handleSubmit: PropTypes.func,
}

export default ReviewForm
