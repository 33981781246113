import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { ReviewAccordion, ReviewAccordionDetails, ReviewAccordionSummary, ReviewGuidelinesTypography } from './styled'

const ReviewGuidelines = ({ dataTestIdPrefix }) => {
  const [expanded, setExpanded] = useState(false)
  const handleChange = () => setExpanded(current => !current)

  return (
    <ReviewAccordion expanded={expanded} onChange={handleChange}>
      <ReviewAccordionSummary
        data-testid={`${dataTestIdPrefix}guidelines-accordion`}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="review-guidelines-content"
        id="review-guidelines-header"
      >
        <ReviewGuidelinesTypography>{`${expanded ? 'Hide' : 'Review'} Guidelines`}</ReviewGuidelinesTypography>
      </ReviewAccordionSummary>
      <ReviewAccordionDetails>
        <p>
          We value your input and invite you to rate and review your purchases. After leaving a star rating, you may
          also choose to let us know about your purchase in your own words by writing what you like or dislike about the
          product, focusing on the product's features and your own experience using it.
        </p>
        <p>
          If you wish to comment about product selection, pricing, ordering, delivery or other issues, please contact
          our customer support.
        </p>
        <ul>
          <p>Please refrain from including any of the following in your review:</p>
          <li>Obscene or discriminatory language</li>
          <li>Critical or inappropriate comments about other reviews and shoppers</li>
          <li>Advertising, spam, references to other websites or retailers</li>
          <li>Personal information such as email addresses, phone numbers or physical addresses</li>
        </ul>
        <p>
          All reviews are subject to our store{' '}
          <a
            data-testid={`${dataTestIdPrefix}guidelines-tou-link`}
            href="https://legal.roomstogo.com/rtg-online#contract-7siltltgm"
            target="_blank"
          >
            Terms of Use<span className="hide508">(opens in new window)</span>
          </a>
          .
        </p>
      </ReviewAccordionDetails>
    </ReviewAccordion>
  )
}

ReviewGuidelines.propTypes = {
  dataTestIdPrefix: PropTypes.string,
}

export default ReviewGuidelines
