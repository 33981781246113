import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { styled, Typography, useMediaQuery, useTheme } from '@mui/material'
import ReviewProductInfo from './ReviewProductInfo'
import ReviewRating from './ReviewRating'
import ReviewProductModal from './ReviewProductModal'
import { PastOrderContainer } from './styled'

const HeaderWrapper = styled('div')({
  padding: '15px 0',
})
const PastOrdersWrapper = styled('div')({})

const ReviewProductWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const PastOrders = ({ dataTestIdPrefix, searchParams, orders, setSku, setImageURL, setTitle, setUnreviewedItems }) => {
  const { palette } = useTheme()
  const [open, setOpen] = useState(false)
  const [rating, setRating] = useState(0)
  const [currentOrder, setCurentOrder] = useState({})

  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'))

  const handleReviewModal = (order, newValue) => {
    setRating(newValue)
    setCurentOrder(order)
    setOpen(true)
  }

  const handleClose = () => {
    setRating(0)
    setOpen(false)
  }

  return (
    <>
      <HeaderWrapper>
        <Typography color={palette.primary.dark} fontSize="25px" textTransform="uppercase" fontWeight={500}>
          Past Orders
        </Typography>
        <Typography fontSize="18px">
          We would love to hear your thoughts on the items you've previously purchased...
        </Typography>
      </HeaderWrapper>

      <PastOrdersWrapper>
        {orders.map((order, index) => (
          <>
            <ReviewProductWrapper key={`unreviewed_order_${order?.catalogItem?.sku}`}>
              <PastOrderContainer
                sx={{
                  justifyContent: !isMobile ? 'center' : '',
                  flexDirection: isMobile ? 'column' : '',
                  alignItems: !isMobile ? 'center' : '',
                }}
              >
                <ReviewProductInfo
                  dataTestIdPrefix={`${dataTestIdPrefix}success-`}
                  isSuccess
                  showYourOrder={false}
                  prodImageURL={order?.catalogItem?.imageUrl}
                  prodSKU={order?.catalogItem?.sku}
                  prodTitle={order?.catalogItem?.title}
                />
                <ReviewRating
                  isMobile={isMobile}
                  isSuccessPage
                  dtId={`${dataTestIdPrefix}rating-input`}
                  handleRating={handleReviewModal}
                  labelColor={palette.grey[600]}
                  order={order}
                  rating={order === currentOrder ? rating : 0}
                />
              </PastOrderContainer>
            </ReviewProductWrapper>
          </>
        ))}
        <ReviewProductModal
          initialRating={rating}
          setSku={setSku}
          setImageURL={setImageURL}
          setTitle={setTitle}
          setUnreviewedItems={setUnreviewedItems}
          searchParams={searchParams}
          open={open}
          handleClose={handleClose}
          order={currentOrder?.catalogItem}
        />
      </PastOrdersWrapper>
    </>
  )
}

PastOrders.propTypes = {
  dataTestIdPrefix: PropTypes.string,
  searchParams: PropTypes.object,
  orders: PropTypes.array,
  setSku: PropTypes.func,
  setImageURL: PropTypes.func,
  setTitle: PropTypes.func,
  setUnreviewedItems: PropTypes.func,
}

export default PastOrders
