import { useQuery } from 'react-query'

const useReviewSubmission = (url, options) =>
  useQuery({
    queryKey: ['submit-review', options],
    queryFn: () => handleSubmit(url, options),
    refetchOnWindowFocus: false,
  })

const handleSubmit = async (url, options) => {
  const response = await fetch(url, options)
  const { status } = response

  const data = await response.json()

  data.status = status

  return data
}

export default useReviewSubmission
